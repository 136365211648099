import { Roles } from "@/constants";

export default [
  {
    path: "users",
    name: "settings",
    component: () => import("@/views/index.vue"),
    meta: {
      title: "Пользователи",
      role: [Roles.superAdmin, Roles.admin],
    },
    children: [
      {
        path: "",
        name: "users",
        component: () => import("@/views/users/list.vue"),
      },
      {
        path: "add",
        name: "user-add",
        component: () => import("@/views/users/add.vue"),
        meta: {
          title: "Добавить",
          role: [Roles.superAdmin],
        },
      },
      {
        path: ":id(\\d+)/edit",
        name: "user-edit",
        props: true,
        component: () => import("@/views/users/edit.vue"),
        meta: {
          title: "Изменить",
          role: [Roles.superAdmin, Roles.admin],
        },
      },
    ],
  },
];
