<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <img
        src="/icons/logo-fav.png"
        alt=""
        style="width: 30px; margin-left: 15px !important"
      />
      <div class="sidebar-brand-full">
        <strong
          style="
            font-family: 'Playfair Display', serif;
            margin-left: 20px;
            white-space: nowrap;
          "
          >МОЙ РЕСТОРАН</strong
        >
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { AppSidebarNav } from './AppSidebarNav'
import { mapGetters } from 'vuex'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['sidebarVisible', 'sidebarUnfoldable']),
  },
}
</script>

<style scoped>
ul.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: #00aced;
  border-radius: 30px;
}
ul.sidebar-nav::-webkit-scrollbar {
  width: 7px;
}
.sidebar-brand {
  justify-content: normal;
}
</style>
