<template>
  <CDropdown variant="nav-item">
    <CDropdownItem style="cursor: pointer" @click="logOut">
      <CIcon icon="cil-account-logout" /> Выйти
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      itemsCount: 42,
    }
  },
  methods: {
    ...mapActions(['logout']),
    logOut() {
      this.logout()
      this.$router.push('/login')
    },
  },
}
</script>
