import { Roles } from "@/constants";

export default [
  {
    path: "my-restaurant",
    name: "my-restaurant",
    component: () => import("@/views/settings/profile.vue"),
    meta: {
      title: "Заведение",
      role: [Roles.owner],
    },
  },
  {
    path: "generating",
    name: "generating-qr",
    component: () => import("@/views/settings/generatingqr.vue"),
    meta: {
      title: "Генерация Qr",
      role: [Roles.owner],
    },
  },
];
