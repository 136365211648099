import './constants'

import { createApp } from "vue";
import "./registerServiceWorker";
import i18n from "@/plugins/i18n";
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import "element-plus/dist/index.css";
import Maska from 'maska'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons/index'
import DocsCallout from '@/components/DocsCallout.vue'
import DocsExample from '@/components/DocsExample.vue'
import _axios, { setToken } from '@/plugins/axios'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification'

if (store.getters.access_token) setToken(store.getters.access_token)

const app = createApp(App)
  app.use(VueAxios, _axios)
  app.use(store)
  app.use(router)
  app.use(ElementPlus)
  app.use(i18n)
  app.use(CoreuiVue)
  app.use(VueToast, {
  position: 'top',
  })
  app.provide('icons', icons)
  app.use(Maska)
  app.component('CIcon', CIcon)
  app.component('DocsCallout', DocsCallout)
  app.component('DocsExample', DocsExample)

  app.mount('#app')
