import { Roles } from '@/constants'

export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/admin/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Меню',
    role: [Roles.owner],
  },
  {
    component: 'CNavItem',
    name: 'Список',
    to: '/admin/meals',
    icon: 'cil-list',
    role: [Roles.owner],
  },
  {
    component: 'CNavItem',
    name: 'Категории',
    to: '/admin/categories',
    icon: 'cil-library',
    role: [Roles.owner],
  },
  {
    component: 'CNavTitle',
    name: 'Настройки',
    role: [Roles.owner],
  },
  {
    component: 'CNavItem',
    name: 'Мой росторан',
    to: '/admin/my-restaurant',
    icon: 'cil-restaurant',
    role: [Roles.owner],
  },
  {
    component: 'CNavItem',
    name: 'Сгенерировать QR',
    to: '/admin/generating',
    icon: 'cil-qr-code',
    role: [Roles.owner],
  },

  {
    component: 'CNavTitle',
    name: 'CRM',
    role: [Roles.superAdmin, Roles.admin],
  },
  {
    component: 'CNavItem',
    name: 'Пользователи',
    to: '/admin/users',
    icon: 'cil-user',
    role: [Roles.superAdmin, Roles.admin],
  },
  {
    component: 'CNavItem',
    name: 'Оплаты',
    to: '/admin/payments',
    icon: 'cil-featured-playlist',
    role: [Roles.superAdmin, Roles.admin],
  },
]
