import _axios, { setToken } from '@/plugins/axios'

export default {
  state: {
    access_token: '',
    refresh_token: '',
    is_authorised: false,
    sidebarVisible: true,
    sidebarUnfoldable: false,
    measureCode: 'portion',
    user: {
      role: 'guest',
    },
  },
  getters: {
    is_authorised: (state: any) => state.is_authorised,
    access_token: (state: any) => state.access_token,
    refresh_token: (state: any) => state.refresh_token,
    sidebarVisible: (state: any) => state.sidebarVisible,
    sidebarUnfoldable: (state: any) => state.sidebarUnfoldable,
    measureCode: (state: any) => state.measureCode,
    user: (state: any) => state.user,
  },
  actions: {
    setMeasureCode(context: any, payload: any) {
      context.commit('SET_MEASURE_CODE', payload)
    },
    login: async ({ commit }: any, { email, password }: any) => {
      const response = await _axios
        .post('login', { email, password })
        .catch((err) => {
          console.error(err)
        })
      if (response?.status === 200 && response.data.access_token) {
        commit('LOG_IN', response.data)
        setToken(response.data.access_token)
        return true
      }
      return false
    },
    logout: ({ commit }: any) => {
      commit('LOG_OUT')
    },
  },
  mutations: {
    SET_MEASURE_CODE: (state: any, measureName: string) => {
      state.measureCode = measureName
    },
    updateSidebarVisible(state: any, { value }: any) {
      state.sidebarVisible = value
    },
    toggleUnfoldable(state: any,) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    toggleSidebar(state: any) {
      state.sidebarVisible = !state.sidebarVisible
    },
    LOG_IN(state: any, { access_token, refresh_token, user }: any) {
      state.is_authorised = true
      state.access_token = access_token
      state.refresh_token = refresh_token
      state.user = user
    },
    LOG_OUT(state: any) {
      state.is_authorised = false
      state.access_token = ''
      state.refresh_token = ''
      setToken('')
      _axios.get('logout')
    },
  },
}
