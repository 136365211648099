<template>
  <footer class="text-center text-white">
    <div class="text-center aboutsite text-dark">
      Разработка сайтов
      <a class="text-dark" href="https://t.me/coder96"
        ><strong> Дониёр</strong></a
      >
    </div>
  </footer>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  width: 100%;
}
.aboutsite {
  background-color: #cbc9c9;
  font-size: 15px;
  text-decoration: none;
}
.aboutsite a {
  text-decoration: none;
}
</style>
