import axios from "axios";
import router from '@/router'

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://artmix.net.kg/api/"
    : "http://qr-menu.local/api/";

const config = {
  // eslint-disable-next-line no-undef
  baseURL: API_URL,
  timeout: 15 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  // headers: {},
};
const _axios = axios.create(config);

export const setToken= (token: string) => {
  _axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401) {
      router.push('/login')
    }
    if (error.response.status === 404) {
      router.push({ name: 'Page404' })
    }
    if (error.response.status === 403) {
      router.push('/pages/403')
    }
    return Promise.reject(error)
  },
);
export default _axios;
