<template>
    <carousel>
      <slide v-for="photo in photos" :key="photo.id">
        <div class="carousel__item"><img class="carousel_img" :src="photo.thumbnail" alt="photo product"></div>
      </slide>
  
      <template #addons>
        <Pagination />
      </template>
    </carousel>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'
  
  export default defineComponent({
    name: 'CarouselImage',
    components: {
      Carousel,
      Slide,
      Pagination,
    },
    props: {
      photos: {
        required: true,
      }
    }
  })
  </script>
  
  <style>
  .carousel__item {
    height: 280px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel__slide {
    padding: 0 5px;
  }
  .carousel_img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }
  .carousel__pagination {
    position: absolute;
    bottom: 25px;
    width: 100%;
    
  }
  .carousel__pagination-button::after {
    border-radius: 50% !important;
    width: 7px !important;
    height: 7px !important;
  }
  .carousel__pagination-item {
    background: rgba(255, 255, 255, 0.5);
    
  }
  .carousel__pagination-item:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .carousel__pagination-item:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  </style>