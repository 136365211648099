import { Roles } from "@/constants";

export default [
  {
    path: "categories",
    name: "categories",
    props: true,
    component: () => import("@/views/index.vue"),
    meta: {
      title: "Категории",
      role: [Roles.owner],
    },
    children: [
      {
        path: "",
        name: "category-list",
        props: true,
        component: () => import("@/views/categories/list.vue"),
        meta: {
          title: "Список",
        },
      },
      {
        path: "add",
        name: "category-add",
        props: true,
        component: () => import("@/views/categories/add.vue"),
        meta: {
          title: "Добавить",
        },
      },
      {
        path: "sort",
        name: "category-sort",
        props: true,
        component: () => import("@/views/categories/sort.vue"),
        meta: {
          title: "Сортировка",
        },
      },
      {
        path: ":id(\\d+)/edit",
        name: "category-edit",
        props: true,
        component: () => import("@/views/categories/edit.vue"),
        meta: {
          title: "Изменить",
        },
      },
    ],
  },
];
