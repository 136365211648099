import { createI18n } from "vue-i18n";
import store from "../store";

export default createI18n({
  locale: store.getters.locale,
  messages: {
    en: {
      restaurant: "My restaurant",
      positions: "elements",
      details: "Details",
      som: "som",
      s: "s",
      measures: {
        pieces: "pc",
        gram: "gr",
        portion: "pt",
        liter: "l",
        kg: "kg",
        cm: "cm",
        ml: "ml",
      },
    },
    ru: {
      restaurant: "ARTMIX",
      positions: "позиции",
      details: "Подробно",
      som: "сом",
      s: "с",
      measures: {
        pieces: "шт",
        gram: "гр",
        portion: "п",
        liter: "л",
        kg: "кг",
        cm: "см",
        ml: "мл",
      },
    },
  },
});
