<template>
  <div class="category">
    <div role="button" @click="toggleMeals" class="category_items">
      <div class="category_icon">
        <img
          class="category_icon-img"
          :src="'/category-icons/' + category.icon + '.svg'"
          alt=""
        />
      </div>
      <div class="category_text">
        <h2 class="category_text-title">
          {{ category.locales[locale]?.name || category.locales["ru"].name }}
        </h2>
        <p class="category_text-quantity">
          {{ category.meals.length }}
          {{ $t("positions") }}
        </p>
      </div>
      <div class="arrow">
        <img v-if="!showMeals" src="/icons/down-arrow-svgrepo-com.svg" alt="" />
        <img v-else src="/icons/up-arrow-svgrepo-com.svg" alt="" />
      </div>
    </div>
    <div class="trans">
      <transition
        name="fade"
        enter-active-class="animate__animated animate__fadeIn  animate__faster"
      >
        <div v-if="showMeals" class="meals">
          <template v-for="product in category.meals" :key="product">
            <Meals v-if="product.photos.length > 0"  :product="product" />
            <MealList v-else :product="product" />
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import "animate.css";
import Meals from "@/components/Meal";
import { mapGetters } from "vuex";
import MealList from "@/components/MealList";

export default {
  name: "Category",
  components: { MealList, Meals },
  props: {
    category: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  data: function () {
    return {
      showMeals: false,
    };
  },
  methods: {
    toggleMeals() {
      this.showMeals = !this.showMeals;
      setTimeout(() => {
        let difference = window.matchMedia("(max-width: 960px)").matches
          ? (window.innerHeight / 100) * 12
          : 320;
        window.scrollTo(
          0,
          this.$el.offsetTop + window.innerHeight / 2 - difference
        );
      }, 100);
    },
  },
};
</script>

<style scoped>
.category {
  position: relative;
}
.category_items {
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #e0e2e7;
  background-color: #fbfbfb;
  position: sticky;
  top: 88px;
  z-index: 6;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  text-decoration: inherit;
  color: inherit;
}
.category_icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.category_text-title {
  font-weight: bold;
}
.category_text-quantity {
  color: #91949d;
  font-size: 14px;
  text-align: left;
}
.category_text {
  margin-top: 10px;
}
.arrow {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 20px;
}
.arrow img {
  width: 100%;
  height: 100%;
}
.trans {
  overflow: hidden;
}
@media screen and (max-width: 960px) {
  .category_items {
    top: 53px;
  }
}
</style>
