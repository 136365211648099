<template>
  <el-header>
    <div class="hero-logo justify-content-between">
      <div>
        <router-link :to="'/'" class="hero-logo-name">
          <strong>{{ myRestaurant.name }}</strong>
        </router-link>
      </div>
      <div>
        <el-dropdown v-if="myRestaurant.multilingual" style="z-index: 5600">
          <el-button plain>
            {{ locale.toUpperCase()
            }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="setLocale('ru')">RU</el-dropdown-item>
              <el-dropdown-item @click="setLocale('en')">EN</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </el-header>
  <div>
    <div
      :style="{
        backgroundImage: 'url(' + myRestaurant.photo + ')',
      }"
      class="background"
    >
      <!--      <div v-if="!!id" class="stol-number">{{ id }}</div>-->
    </div>
  </div>
  <div class="menu">
    <div class="restaurant-name">
      <h1>{{ myRestaurant.name }}</h1>
    </div>
    <div class="category-block">
      <Category
        class="category"
        v-for="(category, index) in myRestaurant.categories"
        :key="index"
        :category="category"
      />
    </div>
    <div class="my-footer">
      <Socialnetworks v-model:myRestaurant="myRestaurant" />
      <Footer />
    </div>
  </div>
</template>

<script>
import Category from "../components/Category";
import Socialnetworks from "@/components/Socialnetworks";
import Footer from "@/components/Footer";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Menu",
  components: { Socialnetworks, Category, Footer },
  data() {
    return {
      myRestaurant: {
        social_networks: {
          instagram: "",
          facebook: "",
        },
      },
    };
  },
  watch: {
    locale() {
      this.$i18n.locale = this.locale;
    },
  },
  computed: {
    ...mapGetters(["locale"]),
  },
  methods: {
    ...mapActions(["setLocale"]),
  },
  mounted() {
    const host = window.location.host;
    this.$http.get("/restaurants/" + host).then(({ data }) => {
      this.myRestaurant = data.data;
      document.querySelector("title").innerText =
        "Каталог " + this.myRestaurant.name + ". заказать, цены ";
      document.querySelector("meta[name='description']").textContent =
        this.myRestaurant.description;
      document.querySelector('meta[property="og:title"]').content =
        "Каталог " + this.myRestaurant.name;
      document.querySelector('meta[property="og:url"]').content =
        "https://" + host;
      document.querySelector('meta[property="og:image"]').content =
        this.myRestaurant.photo_thumbnail;
      document.querySelector('meta[property="og:description"]').content =
        this.myRestaurant.description;
    });
  },
};
</script>

<style lang="sass">
.el-dropdown__popper
  z-index: 5600 !important
  .el-scrollbar
    width: 70px
</style>
<style lang="scss" scoped>
.hero-logo {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  &-name {
    text-decoration: none;
    color: inherit;
  }
  strong {
    margin-left: 20px;
    font-size: 30px;
  }
}
.background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.menu {
  margin-top: 90px;
  width: 50%;
  right: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  background-color: #fbfbfb;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: absolute;
  padding-bottom: 70px;
  .my-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
.category-block {
  min-height: 77vh;
}
.stol-number {
  margin: 0 auto;
  width: 176px;
  height: 109px;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: #fff;
  font-size: 70px;
  text-align: center;
  font-weight: bold;
}
.restaurant-name {
  border-bottom: 2px solid #e0e2e7;
  h1 {
    margin: 20px;
    font-size: 36px;
    font-weight: 700;
  }
}
.el-header {
  z-index: 5555;
  top: 0;
  right: 0;
  position: fixed;
  width: 50%;
  height: 90px;
  background-color: #ffffff;
  border-bottom: solid 1px var(--el-menu-border-color);
}
.logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &-img {
    position: absolute;
    left: 0;
    top: 16px;
    width: 60px;
  }

  &-text {
    font-size: 30px;
    font-weight: 700;
  }
}

@media screen and (max-width: 960px) {
  .hero-logo {
    display: inline-flex;
    align-items: center;
    img {
      width: 40px;
    }
    strong {
      margin-left: 15px;
      font-size: 20px;
    }
  }
  .background {
    width: 100%;
    top: 53px;
    height: 45vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .stol-number {
    width: 150px;
    height: 72px;
    font-size: 50px;
  }
  .category-block {
    min-height: 47vh;
  }
  .menu {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    max-width: 640px;
    margin: 50vh auto 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .restaurant-name {
    flex: 0 0 auto;
  }
  .category-block {
    flex: 1 0 auto;
  }
  .footer {
    flex: 0 0 auto;
  }
  .el-header {
    width: 100%;
    height: 58px;
  }
  .logo {
    &-img {
      top: 7px;
      width: 38px;
    }
    &-text {
      font-size: 22px;
    }
  }
}
@media screen and (max-width: 500px) {
  .menu {
    margin: 97% auto 0;
  }
  .background {
    height: 43vh;
  }
}
</style>
