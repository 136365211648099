export default {
  state: {
    selectCategory: null,
  },
  getters: {
    selectCategory: (state: any) => state.selectCategory,
  },
  actions: {
    setSelectCategory(context: any, payload: string) {
      context.commit('SET_SELECTCATEGORY', payload)
    },
  },
  mutations: {
    SET_SELECTCATEGORY: (state: any, categotyId: string|number) => {
      state.selectCategory = categotyId
    },
  },
}
