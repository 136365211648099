import { Roles } from "@/constants";

export default [
  {
    path: "meals",
    name: "meals",
    props: true,
    component: () => import("@/views/index.vue"),
    meta: {
      title: "Меню",
      role: [Roles.owner],
    },
    children: [
      {
        path: "",
        name: "meal-list",
        component: () => import("@/views/meals/list.vue"),
        meta: {
          title: "Список",
        },
      },
      {
        path: "add",
        name: "meal-add",
        props: true,
        component: () => import("@/views/meals/add.vue"),
        meta: {
          title: "Добавить",
        },
      },
      {
        path: ":id",
        name: "show",
        props: true,
        component: () => import("@/views/meals/show.vue"),
        meta: {
          title: "Продукт",
        },
      },
      {
        path: ":id(\\d+)/edit",
        name: "meal-edit",
        props: true,
        component: () => import("@/views/meals/edit.vue"),
        meta: {
          title: "Изменить",
        },
      },
      {
        path: "sort/:category_id",
        name: "meal-sort",
        props: true,
        component: () => import("@/views/meals/sort.vue"),
        meta: {
          title: "Добавить",
        },
      },
    ],
  },
];
