<template>
  <div class="networks">
    <div class="footer-icons">
      <div class="col">
        <a class="social" :href="'tel:+' + myRestaurant.phone">
          <img class="social-icon" src="/icons/telephone-icon.svg" alt="" />
        </a>
      </div>

      <div class="col">
        <a class="social" :href="'https://wa.me/' + myRestaurant.phone">
          <img src="/icons/whatsapp.svg" class="social-icon" alt="" />
        </a>
      </div>

      <div
        v-if="myRestaurant.social_networks.instagram !== null"
        class="col"
      >
        <a class="social" :href="myRestaurant.social_networks.instagram">
          <img src="/icons/instagram-icon.svg" class="social-icon" alt="" />
        </a>
      </div>

      <div v-if="myRestaurant.social_networks.facebook !== null" class="col">
        <a class="social" :href="myRestaurant.social_networks.facebook">
          <img src="/icons/facebook-icon.svg" class="social-icon" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Socialnetworks",
  props: {
    myRestaurant: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.networks {
  width: 100%;
  flex-shrink: 0;
  background-color: #e0e2e7;
}
.footer-icons {
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 10px;
  display: flex;
}
.col {
  align-items: center;
}
</style>
