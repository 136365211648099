<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li
        v-for="item in breadcrumbs"
        :key="item"
        :class="{ 'breadcrumb-item': true, active: item.active }"
        aria-current="page"
      >
        <router-link
          style="text-decoration: none"
          :class="{ 'breadcrumb-item': true, active: item.active }"
          :to="item.active ? '' : item.path"
          >{{ item.name }}</router-link
        >
      </li>
    </ol>
  </nav>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active:
            route.path === router.currentRoute.value.fullPath ||
            route.name === router.currentRoute.value.name,
          name: route.meta.title,
          path: route.path,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
}
</script>
