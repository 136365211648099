import {h, resolveComponent} from "vue";
import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";

import store from "@/store";
import Menu from "@/views/Menu.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import meals from "./routes/meals";
import categories from "./routes/categories";
import common from "./routes/common";
import payments from "./routes/payments";
import users from "./routes/users";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "restaurant",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Menu,
    meta: {
      need_auth: false,
    },
    children: [
      {
        path: "/pages",
        redirect: "/pages/404",
        name: "Pages",
        meta: {
          need_auth: false,
        },
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
      },
    ],
  },
  {
    path: '/admin',
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: 'admin',
        component: () => import("@/views/index.vue"),
        redirect: "/admin/dashboard",
        meta: {
          title: 'Админка',
        },
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          title: 'Dashboard',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      ...meals,
      ...categories,
      ...common,
      ...users,
      ...payments,
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    meta: {
      need_auth: false,
    },
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '403',
        name: 'forbidden',
        component: () => import('@/views/pages/Page403.vue'),
      },
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register.vue'),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      need_auth: false,
    },
    component: () => import("@/views/Login.vue"),
  },
];

const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeEach((to: any) => {
    if (to.name !== 'Login' && !store.getters.access_token && to.meta?.need_auth !== false) {
        return {
            name: 'Login',
        }
    }
    if (!to.meta.role || to.meta.role.includes(store.getters.user.role)) {
        return true
    }
    return {
        name: 'forbidden',
    }
})

export default router;
