import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import app from './modules/app'
import settings from './modules/settings'
import categories from './modules/categories'

const _store = new Vuex.Store({
  state: {
    locale: "ru",
    measures: [
      { code: 'gram', name: 'грам' },
      { code: 'pieces', name: 'штук' },
      { code: 'portion', name: 'порция' },
      { code: 'l', name: 'литр' },
      { code: 'ml', name: 'мл' },
      { code: '', name: '-' },
    ],
  },
  getters: {
    locale: (state) => {
      return state.locale;
    },
    measures: (state) => {
      return state.measures
    },
  },
  mutations: {
    setLocale(state, value) {
      state.locale = value;
    },
  },
  actions: {
    setLocale: ({ commit }, locale) => {
      commit("setLocale", locale);
    },
  },
  modules: {
    app,
    settings,
    categories,
  },
  plugins: [
    createPersistedState({
      key: "qr-menu",
    }),
  ],
});

export default _store
