import { Roles } from "@/constants";

export default [
  {
    path: "payments",
    name: "payments",
    component: () => import("@/views/index.vue"),
    meta: {
      title: "Оплаты",
      role: [Roles.superAdmin, Roles.admin],
    },
    children: [
      {
        path: "",
        name: "payments-list",
        component: () => import("@/views/payments/list.vue"),
        meta: {
          role: [Roles.superAdmin, Roles.admin],
        },
      },
      {
        path: "add",
        name: "payment-add",
        component: () => import("@/views/payments/add.vue"),
        meta: {
          title: "Добавить",
          role: [Roles.superAdmin, Roles.admin],
        },
      },
    ],
  },
];
