<template>
  <div :class="{ meal: true, 'no-description': description.length === 0 }">
    <div class="meal_text el-card">
      <div class="meal_text-prices">
        <div class="meal_text-name">
          {{ product.locales[locale]?.name || product.locales["ru"].name }}
        </div>
      </div>
      <div class="dashed"></div>
      <div class="prices">
        <div
          class="prices_text"
          v-for="(price, index) in product.prices"
          :key="index"
        >
          {{ price.quantity + $t("measures." + price.measure) }} ...
          {{ price.price }}
          {{ $t("som") }}
        </div>
      </div>
    </div>
    <p class="meal_text-description" v-if="description">{{ description }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MealList",
  props: {
    product: {
      type: Object,
      required: true,
      default() {
        return {
          name: "",
          price: "",
          description: "",
        };
      },
    },
  },
  data() {
    return {
      showPrice: false,
    };
  },
  computed: {
    ...mapGetters(["locale"]),
    morePrice() {
      return this.product.prices.length > 1;
    },
    description() {
      return (
        this.product.locales[this.locale]?.description ||
        this.product.locales["ru"].description
      );
    },
  },
};
</script>

<style scoped lang="sass">
.meal
  border-bottom: 1px solid #e0e2e7
  padding: 10px

  &.no-description
    padding: 0 10px
    border-bottom: none

    .meal_text
      border-width: 0 1px
      border-bottom-width: 1px
      border-radius: 0

    &:last-child
      .meal_text
        border-radius: 0 0 4px 4px

    &:first-child
      margin-top: 5px

      .meal_text
        border-radius: 4px 4px 0 0

.meal_text
  display: flex
  justify-content: space-between
  padding: 7px 5px

  .dashed
    flex-grow: 1
    border-bottom: 1px dot-dot-dash black

  .meal_text-name
    text-align: left

  .prices_text
    text-align: right
    white-space: nowrap
</style>
