export default {
  state: {
    qrType: 'dots',
    qrColor: '#263cad',
    stolNumber: 1,
  },
  getters: {
    qrType: (state: any) => state.qrType,
    qrColor: (state: any) => state.qrColor,
    stolNumber: (state: any) => state.stolNumber,
  },
  actions: {
    setQrType(context: any, payload: string) {
      console.log(payload)
      context.commit('SET_QRTYPE', payload)
    },
    setStolNumber(context: any, payload: string) {
      console.log(payload)
      context.commit('SET_STOLNUMBER', payload)
    },
    setColor(context: any, payload: string) {
      console.log(payload)
      context.commit('SET_QRCOLOR', payload)
    },
  },
  mutations: {
    SET_QRTYPE: (state: any, payload: string) => {
      state.qrType = payload
    },
    SET_STOLNUMBER: (state: any, payload: string) => {
      state.stolNumber = payload
    },
    SET_QRCOLOR: (state: any, payload: string) => {
      state.qrColor = payload
    },
  },
}
